<template>
  <div>
    <div class="head">
      <div class="childImg">
        <img v-if="obj.gender == 0" src="../../common/img/girl.png" />
        <img v-if="obj.gender == 1" src="../../common/img/boy.png" />
      </div>
    </div>
    <div style="margin: 10px 20px 0 20px">
      <van-field
        v-model="obj.name"
        label="宝贝姓名"
        placeholder="请输入宝贝姓名"
        input-align="right"
        style="border-top-left-radius: 7px; border-top-right-radius: 7px"
      />
      <div class="van-cell van-field">
        <div class="van-cell__title van-field__label">
          <span>性别</span>
        </div>
        <div class="van-cell__value van-field__value">
          <div class="van-field__body">
            <van-radio-group v-model="obj.gender">
              <van-radio :name="1" style="margin-right: 20px">男</van-radio>
              <van-radio :name="0">女</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <van-field
        v-model="obj.birthday"
        name="生日"
        label="生日"
        placeholder="请选择"
        input-align="right"
        readonly
        style="border-bottom-left-radius: 7px; border-bottom-right-radius: 7px"
        @click="showBirthday = true"
      />
      <div class="btn">
        <van-button type="info" style="width:260px" round @click="submit()"
          >提交信息</van-button
        >
      </div>
    </div>
    <van-popup
      v-model="showBirthday"
      position="bottom"
      :style="{ height: '40%' }"
      safe-area-inset-bottom
    >
      <van-datetime-picker
        type="date"
        :value="new Date(obj.birthday)"
        :formatter="formatter"
        title="选择生日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onconfirm"
        @cancel="showBirthday = false"
      />
    </van-popup>
  </div>
</template>

<script>
import api from '../../common/js/api';
import { Toast } from 'vant';
export default {
  data() {
    return {
      showBirthday: false,
      obj: { birthday: '2001-02-02' },
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2025, 11, 31),
      id: '',
    };
  },
  created() {
    this.id = window.sessionStorage.getItem('childId');
    this.getInfo();
  },
  components: {},

  computed: {},

  mounted() {},

  methods: {
    submit() {
      let params = {
        id: this.id,
        name: this.obj.name,
        gender: this.obj.gender,
        birthday: this.obj.birthday,
      };
      this.$post(api.editInfoService, params).then(() => {
        Toast('编辑成功');
      });
    },
    getInfo() {
      this.$post(api.childInfoService, {
        id: this.id,
      }).then((res) => {
        console.log(res.data);
        this.obj = res.data;
      });
    },
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`;
      } else if (type === 'month') {
        return `${value}月`;
      } else if (type === 'day') {
        return `${value}日`;
      } else if (type === 'hour') {
        return `${value}时`;
      } else if (type === 'minute') {
        return `${value}分`;
      } else if (type === 'second') {
        return `${value}秒`;
      }
      return value;
    },
    onconfirm(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      this.$set(this.obj, 'birthday', `${year}-${month}-${day}`);
      this.showBirthday = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  height: 190px;
  background-image: linear-gradient(#1e78ff, #72c2ff);
  background-size: contain;
  text-align: center;
  color: white;
  .childImg {
    transform: translate(-50%, -50%);
    position: relative;
    top: 60%;
    left: 50%;
    img {
      width: 70px;
      height: 70px;
    }
  }
}
.btn {
  margin-top: 30px;
  text-align: center;
}
/deep/.van-field__body {
  justify-content: flex-end;
}
/deep/.van-radio-group {
  display: flex;
}
</style>
